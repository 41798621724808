<template>
  <div>
    <v-card class="mt-1 pb-5" flat>
      <v-card-title>
        <p class="header-2 mb-0">{{ $t("quickbooks_settings") }}</p>
      </v-card-title>
      <v-card-text v-if="selectedSyncQBO && selectedSyncQBO.value === 'bills'">
        <v-alert dark color="primary" icon="mdi-alert" outlined prominent>
          <span>Reminder:</span>
          <ul class="reminder-list">
            <li>{{ $t('import_bill_reminder_first') }}</li>
            <li>{{ $t('import_bill_reminder_second') }}</li>
            <li>{{ $t('import_bill_reminder_third') }}</li>
          </ul>
        </v-alert>
      </v-card-text>
      <v-card-text>
        <v-sheet class="d-flex justify-space-between">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" height="40" class="text-capitalize" color="primary" :disabled="isImporting">
                {{ $t('sync') + (selectedSyncQBO ? ': ' + selectedSyncQBO.text : '') }}
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card width="300">
              <v-card-text>
                <v-list dense class="py-0">
                  <v-list-item-group
                    v-model="selectedSyncQBO"
                    color="primary"
                    @change="fetchSyncQBO"
                  >
                    <v-list-item
                      v-for="item in syncQBOItems"
                      :key="item.value"
                      :value="item"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-sheet class="d-flex align-center">
            <span class="mr-2 header-2 pb-0">{{ $t('auto_sync') }}</span>
            <v-btn
              class="pa-4"
              small
              rounded
              outlined
              :color="isAutoSynced ? 'success': 'error'"
              :loading="isAutoSyncLoading"
              @click="onConfirmSync"
            >
              <div v-if="isAutoSynced" class="d-flex align-center justify-space-around" style="min-width: 60px">
                {{ $t('on') }}
                <v-icon>mdi-check-circle</v-icon>
              </div>
              <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                {{ $t('off') }}
                <v-icon>mdi-minus-circle</v-icon>
              </div>
            </v-btn>
          </v-sheet>
        </v-sheet>

        <!-- Vendors Table -->
        <div v-if="isImporting || isImportLoaded" class="mt-8 mt-sm-4 elevation-1">
          <v-data-table
            v-if="(selectedSyncQBO && selectedSyncQBO.value === 'vendors')"
            :headers="vendorTableHeader"
            :items="importData"
            item-key="Id"
            class="v-table-middle-align"
            :loading="isSyncingImport || isImporting"
          >
            <template v-slot:top>
              <div class="d-flex justify-space-between align-center">
                <h1 class="text-h6 pa-4">{{ selectedSyncQBO ? selectedSyncQBO.text : '' }}</h1>
                <v-btn class="mr-2" color="primary" :disabled="!isImportLoaded" @click="onImport" :loading="isSyncingImport">
                  <v-icon>mdi-import</v-icon>
                  {{ $t('import') }}
                </v-btn>
              </div>
              <v-alert v-if="importErrorMessage" type="error">{{ importErrorMessage }}</v-alert>
              <v-alert v-if="importMessage" type="success">{{ importMessage }}</v-alert>
            </template>
          </v-data-table>
        </div>

        <!-- Items Table -->
        <div v-if="isImporting || isImportLoaded" class="mt-4 elevation-1">
          <v-data-table
            v-if="(selectedSyncQBO && selectedSyncQBO.value === 'items')"
            :headers="itemTableHeader"
            :items="importData"
            item-key="Id"
            class="v-table-middle-align"
            :loading="isSyncingImport || isImporting"
          >
            <template v-slot:top>
              <div class="d-flex justify-space-between align-center">
                <h1 class="text-h6 pa-4">{{ selectedSyncQBO ? selectedSyncQBO.text : '' }}</h1>
                <v-btn class="mr-2" color="primary" :disabled="!isImportLoaded" @click="onImport" :loading="isSyncingImport">
                  <v-icon>mdi-import</v-icon>
                  {{ $t('import') }}
                </v-btn>
              </div>
              <v-alert v-if="importErrorMessage" type="error">{{ importErrorMessage }}</v-alert>
              <v-alert v-if="importMessage" type="success">{{ importMessage }}</v-alert>
            </template>
          </v-data-table>
        </div>

        <!-- Account-Based Items Table -->
        <div v-if="isImporting || isImportLoaded" class="mt-4 elevation-1">
          <v-data-table
            v-if="(selectedSyncQBO && selectedSyncQBO.value === 'account-based-items')"
            :headers="accountBasedItemTableHeader"
            :items="importData"
            item-key="Id"
            class="v-table-middle-align"
            :loading="isSyncingImport || isImporting"
          >
            <template v-slot:top>
              <div class="d-flex justify-space-between align-center">
                <h1 class="text-h6 pa-4">{{ selectedSyncQBO ? selectedSyncQBO.text : '' }}</h1>
                <v-btn class="mr-2" color="primary" :disabled="!isImportLoaded" @click="onImport" :loading="isSyncingImport">
                  <v-icon>mdi-import</v-icon>
                  {{ $t('import') }}
                </v-btn>
              </div>
              <v-alert v-if="importErrorMessage" type="error">{{ importErrorMessage }}</v-alert>
              <v-alert v-if="importMessage" type="success">{{ importMessage }}</v-alert>
            </template>
          </v-data-table>
        </div>

        <!-- Bills Table -->
        <div v-if="isImporting || isImportLoaded" class="mt-4 elevation-1">
          <v-data-table
            v-if="(selectedSyncQBO && ['bills', 'bill-payments'].includes(selectedSyncQBO.value))"
            :headers="selectedSyncQBO.value === 'bills' ? billTableHeader : billPaymentTableHeader"
            :items="importData"
            item-key="Id"
            class="v-table-middle-align"
            :single-select="false"
            show-select
            v-model="selectedImports"
            :loading="isSyncingImport || isImporting"
          >
            <template v-slot:top>
              <div class="d-flex justify-space-between align-center">
                <h1 class="text-h6 pa-4">{{ selectedSyncQBO ? selectedSyncQBO.text : '' }}</h1>
                <v-btn class="mr-2" color="primary" :disabled="!isImportLoaded" @click="onImportQBOBilling" :loading="isSyncingImport">
                  <v-icon>mdi-import</v-icon>
                  {{ $t('import') }} ({{ selectedImports.length }})
                </v-btn>
              </div>
              <v-alert v-if="importErrorMessage" type="error">{{ importErrorMessage }}</v-alert>
              <v-alert v-if="importMessage" type="success">{{ importMessage }}</v-alert>
              <v-alert v-if="currentBillImport" type="success">{{ currentBillImport }}</v-alert>
            </template>
            <template v-slot:item.import="{item}">
              <span class="success--text" v-if="item.imported === true"><v-icon>mdi-check</v-icon> {{ $t('imported') }}</span>
              <span class="red--text" v-if="item.imported === false"><v-icon color="red">mdi-close</v-icon>{{ item.importMessage }}</span>
            </template>
          </v-data-table>
        </div>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showConfirmSync"
      max-width="400"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
    <v-card :loading="isAutoSyncLoading">
      <v-card-title class="pa-0">
        <v-toolbar light elevation="1" class="">
          <v-toolbar-title>{{ $t('message') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showConfirmSync = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-sheet class="mt-6">
          <span class="header-2">{{ $t('confirm_action') }}: {{ $t('auto_sync') }} {{ isAutoSynced ? $t('off') : $t('on') }}</span>
        </v-sheet>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="onToggleSync" color="primary" :loading="isAutoSyncLoading">{{ $t('yes') }}</v-btn>
        <v-btn @click="showConfirmSync = false" outlined text :disabled="isAutoSyncLoading">{{ $t('no') }}</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { mapActions, mapGetters } from "vuex";
import { apiErrorMessage, getErrorMessage } from "@/utils/general";
import AkauntingService from '@/services/akaunting/akaunting.service';
export default {
  components: {
  },
  data() {
    return {
      isImporting: false,
      isImportLoaded: false,
      selectedSyncQBO: null,
      importMessage: '',
      selectedImports: [],
      syncQBOItems: [
        {
          value: 'account-based-items',
          text: 'Account-Based Items'
        },
        {
          value: 'bills',
          text:  'Bills',
        },
        {
          value: 'bill-payments',
          text:  'Billing Payments',
        },
        {
          value: 'items',
          text:  'Products & Services',
        },
        {
          value: 'vendors',
          text: 'Vendors'
        }
      ],
      importData: [],
      isSyncingImport: false,
      isSyncingImportFailed: false,
      isSyncingImportSuccess: false,
      importErrorMessage: '',
      currentBillImport: '',
      isAutoSynced: false,
      isAutoSyncLoading: false,
      showConfirmSync: false,
    };
  },
  beforeMount() {
    this.getSyncValue();
  },

  computed: {
    vendorTableHeader() {
      return [{
        text: this.$t('display_name'),
        value: "DisplayName",
        class: "text-uppercase th--text font-weight-bold"
      }, {
        text: this.$t('currencyname'),
        value: "CurrencyRef",
        class: "text-uppercase th--text font-weight-bold"
      }];
    },

    itemTableHeader() {
      return [{
        text: this.$t('name'),
        value: "Name",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('type'),
        value: "Type",
        class: "text-uppercase th--text font-weight-bold"
      },
      ];
    },

    accountBasedItemTableHeader() {
      return [{
        text: this.$t('name'),
        value: "Name",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('currencyname'),
        value: "CurrencyRef",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('classification'),
        value: "Classification",
        class: "text-uppercase th--text font-weight-bold"
      },];
    },

    billTableHeader() {
      return [{
        text: this.$t('document_number'),
        value: "DocNumber",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('due_date'),
        value: "DueDate",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('currencyname'),
        value: "CurrencyRef",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('amount'),
        value: "TotalAmt",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('balance'),
        value: "Balance",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: '',
        value: "import",
      },];
    },

    billPaymentTableHeader() {
      return [{
        text: this.$t('document_number'),
        value: "DocNumber",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('currencyname'),
        value: "CurrencyRef",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('amount'),
        value: "TotalAmt",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: this.$t('date'),
        value: "TxnDate",
        class: "text-uppercase th--text font-weight-bold"
      },{
        text: '',
        value: "import",
      },];
    },

    hasSelectedImport () {
      return Boolean(this.selectedImports.length);
    },

  },

  methods: {

    async onImport() {
      if(this.isSyncingImport) {
        return;
      }
      this.isSyncingImport = true;
      this.isSyncingImportFailed = false;
      this.isSyncingImportSuccess = false;
      this.importMessage = '';
      this.importErrorMessage = '';
      try {
        let response = {};
        switch(this.selectedSyncQBO.value) {
          case 'account-based-items':
            response = await AkauntingService.importQBOAccountBasedItems({run_import : 1});
            break;
          case 'items':
            response = await AkauntingService.importQBOItems({run_import : 1});
            break;
          case 'vendors':
            response = await AkauntingService.importQBOVendors({run_import : 1});
            break;
          // case 'bills':
            // response = await AkauntingService.importQBOBills({})
        }
        this.importMessage = response.data.data.message || response.data.message || '';
        this.isSyncingImport = false;
        this.isSyncingImportSuccess = true;
      } catch(error) {
        this.isSyncingImportFailed = true;
        this.isSyncingImport = false;
        this.importErrorMessage = this.$t('failed_qbo_import_message');
      }
    },

    async fetchSyncQBO() {
      if(this.isImporting) {
        return;
      }
      this.isImporting = true;
      this.isImportLoaded = false;
      this.importData = [];
      this.selectedImports = [];
      this.importMessage = '';
      this.importErrorMessage = '';
      try {
        let response = {};

        switch(this.selectedSyncQBO.value) {
          case 'account-based-items':
            response = await AkauntingService.importQBOAccountBasedItems({});
            break;
          case 'items':
            response = await AkauntingService.importQBOItems({});
            break;
          case 'vendors':
            response = await AkauntingService.importQBOVendors({});
            break;
          case 'bills':
            response = await AkauntingService.fetchImportQBOBills({})
            break;
          case 'bill-payments':
            response = await AkauntingService.fetchImportQBOBillPayments({})
            break;
        }

        this.isImporting = false;
        this.isImportLoaded = true;
        this.importMessage = response.data.message;
        const data = response.data?.data?.data || {};
        this.importData = Object.keys(data).map(key => data[key]);

      } catch(error) {
        this.isImporting = false;
        this.isImportLoaded = true;
      }
    },

    async onImportQBOBilling() {
      if(this.isSyncingImport || this.selectedImports.length === 0) {
        return;
      }
      this.isSyncingImport = true;
      this.isSyncingImportFailed = false;
      this.isSyncingImportSuccess = false;
      this.importMessage = '';
      this.importErrorMessage = '';
      this.currentBillImport = '';
      try {

        const requests = this.selectedImports.map(async entity => {
          // this.currentBillImport = entity.DocNumber;
          return this.selectedSyncQBO.value === 'bills' ? AkauntingService.importQBOBills({ entity }) : AkauntingService.importQBOBillPayments({ entity });
          // try {
            // return {success: true, id: entity.Id};
          // } catch(error) {
          //   return {success: false, id: entity.Id, message: getErrorMessage(error)};
          // }
        });

        const responses = await Promise.allSettled(requests);

        const response = responses.map(record => {
          if(record.status === 'fulfilled') {
            const data = JSON.parse(record.value.config.data);
            return {success: true, id: data.entity.Id};
          } else if(record.status === 'rejected') {
            const data = JSON.parse(record.reason.config.data);
            return {success: false, id: data.entity.Id, message: getErrorMessage(record.reason.response.data)};
          }
        });

        // const response = await Promise.all(requests);

        const ids = response.filter(record => record.success).flatMap(record => record.id);
        const failedIds = response.filter(record => !record.success).flatMap(record => record.id);
        const failedMessages = {};
        response.filter(record => !record.success).forEach(record => {
          failedMessages[record.id] = record.message;
        })

        this.importData = this.importData
          .map(record => {
            if(failedIds.includes(record.Id)) {
              return {...record, importMessage: failedMessages[record.Id], imported: false};
            }
            if(ids.includes(record.Id)) {
              return {...record, imported: true};
            }
            return {...record, importMessage: null, imported: null,};
          });

        this.selectedImports = [];

        this.importMessage = ids.length + ' ' + this.$t('records_imported').toLowerCase() +' ' + failedIds.length + ' ' + this.$t('records_failed_imported').toLowerCase();
        this.isSyncingImport = false;
        this.isSyncingImportSuccess = true;

      } catch(error) {
        console.log(error);
        this.isSyncingImportFailed = true;
        this.isSyncingImport = false;
        this.importErrorMessage = this.$t('failed_qbo_import_message');
      }
    },

    async getSyncValue() {
      this.isAutoSyncLoading = true;
      try {
        const { data } = await AkauntingService.getAutoSync();
        if(data.data && data.data.value === '1') {
          this.isAutoSynced = true;
        }
        this.isAutoSyncLoading = false;
      } catch(error) {
        this.isAutoSyncLoading = false;
        apiErrorMessage(error);
      }
    },

    onConfirmSync() {
      this.showConfirmSync = true;
    },

    async onToggleSync() {
      if(this.isAutoSyncLoading) {
        return;
      }
      this.isAutoSyncLoading = true;
      try {
        this.isAutoSynced = !this.isAutoSynced;
        this.showConfirmSync = false;
        await AkauntingService.setAutoSync({auto_sync: this.isAutoSynced});
        this.isAutoSyncLoading = false;
      } catch(error) {
        this.isAutoSyncLoading = false;
        this.showConfirmSync = false;
        apiErrorMessage(error);
      }
    }

  },

};
</script>
<style lang="scss" scoped>
.vue-app {
  background: none;
}

.v-tabs {
  border-bottom: 1px solid #ddd;

  .v-tab {
    color: #0889a0;
  }
}
.container {
  background-color: none;
}

.w-100 {
  width: 100%;
}

.v-list-item {
  min-height: 36px;
}
.row-stripe:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}
.dialog {
  z-index: 9999;
}

.reminder-list li {
  list-style-type: disc;
}
</style>