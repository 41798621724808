<template>
  <div>
    <div>
      <v-data-table
        :headers="tableHeader"
        :items="tableData"
        class="elevation-1 v-table-middle-align"
        v-model="selectedCurrencies"
        :loading="isCategoryDataLoading"
        disable-pagination
        :hide-default-footer="true"
        :item-class="rowActive"
      >
        <template v-slot:top>
          <div class="d-block d-sm-flex d-md-flex d-lg-flex pa-4">
            <div class="flex-grow-1">
              <p class="header-2 mb-0 pt-2">{{ $t("category") }}</p>
            </div>
            <div class="d-sm-flex d-inline">
              <v-text-field
                v-model="searchText"
                :label="$t('dashboard_seach_placeholder')"
                prepend-inner-icon="mdi-magnify"
                clearable
                hide-details
                flat
                solo
                outlined
                dense
              ></v-text-field>
              <div class="ml-sm-2 ml-0 mt-2 mt-sm-0">
                <v-btn
                  class="form-border text-capitalize"
                  color="primary"
                  @click="onToggleCategoryForm"
                  height="38"
                  block
                >
                  {{ $t("add_category") }}
                </v-btn>
              </div>
            </div>
          </div>
          <v-divider />
        </template>
        <template v-slot:item.color="{item}">
          <v-sheet :color="item.color" class="pa-2">{{ item.color }}</v-sheet>
        </template>
        <template v-slot:item.enabled="{item}">
          <v-btn small rounded outlined :color="item.enabled ? 'success': 'error'" class="pa-4">
            <div v-if="item.enabled" class="d-flex align-center justify-space-around" style="min-width: 60px">
              <v-icon>mdi-check-circle</v-icon>
              {{ $t('yes') }}
            </div>
            <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
              <v-icon>mdi-minus-circle</v-icon>
              {{ $t('no') }}
            </div>
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                primary
                v-on="on"
                color="primary"
                text
                outlined
                small
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="onSelectEditCategoryData(item)">
                <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="onSelectCategoryInformation(item)">
                <v-list-item-title>{{ $t("view") }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="onSelectCategoryToDelete(item)">
                <v-list-item-title class="red--text">{{
                  $t("delete")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:foot>
          <tfoot>
            <tr>
              <td colspan="12" class="text-right">
                <div class="d-none d-md-flex justify-center justify-sm-end">
                  <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                  <h6 class="text-subtitle-1 mt-2 labelcolor--text">
                    {{ (pagination.total || 0).toLocaleString() }}
                    <span class="">{{ $t("records") }}</span>
                  </h6>
                  <v-spacer />
                  <div class="select-page-limit">
                    <v-select
                      v-model="pageLimit"
                      :items="[10, 20, 25]"
                      label="Per page"
                      hide-details
                      dense
                      flat
                      solo
                      @change="onChangePageLimit"
                    ></v-select>
                  </div>
                  <pagination
                    :total="pagination.total_pages || 1"
                    :current-page="currentPage"
                    :total-visible="10"
                    @pageSelected="onPaginationClick"
                  >
                  </pagination>
                </div>
                <div class="d-block d-md-none">
                  <div class="d-flex align-center">
                    <h6 class="text-subtitle-1 mt-2 labelcolor--text">
                      {{ (pagination.total || 0).toLocaleString() }}
                      <span class="">{{ $t("records") }}</span>
                    </h6>
                    <v-spacer />
                    <div class="select-page-limit">
                      <v-select
                        v-model="pageLimit"
                        :items="[10, 20, 25]"
                        label="Per page"
                        hide-details
                        dense
                        flat
                        solo
                        @change="onChangePageLimit"
                      ></v-select>
                    </div>
                  </div>
                  <div>
                    <pagination
                      :total="pagination.total_pages || 1"
                      :current-page="currentPage"
                      :total-visible="10"
                      @pageSelected="onPaginationClick"
                    >
                    </pagination>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
      <v-snackbar
        timeout="2000"
        vertical
        :color="snackbarOption.color"
        v-model="showSnackbar"
        bottom
      >
        <v-icon v-if="snackbarOption.icon">{{
          snackbarOption.icon
        }}</v-icon>
        {{ snackbarOption.message }}
      </v-snackbar>
    </div>
    <category-form
      :open="showFormDialog"
      :is-edit-mode="isEditMode"
      :form-values="selectedCategoryData"
      @toggle="onToggleCategoryForm"
    ></category-form>
    <category-delete
      :open="showDeleteModal"
      :form-data="selectedDataToDelete"
      @toggle="onToggleDeleteCategoryForm"
    ></category-delete>
    <v-dialog
      v-model="showInfoModal"
      max-width="500"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{
              selectedCategoryInformation.name
            }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
              class="d-none d-sm-flex"
              color="primary"
              @click="onSelectEditCategoryData(selectedCategoryInformation)"
            >
              {{ $t("Edit") }}
            </v-btn>
            <v-btn
              text
              outlined
              class="ml-1 mr-4 d-none d-sm-flex"
              @click="onSelectCategoryToDelete(selectedCategoryInformation)"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              icon
              class="d-none d-sm-flex"
              @click="onToggleCategoryInformationModal"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="py-4 pb-8">
          <v-row
            no-gutters
            v-for="item in tableHeader"
            :key="item.text"
            class="row-stripe py-2 px-1"
          >
            <v-col cols="6" class="text-left ">
              <h5 class="labelcolor--text">{{ item.text }}</h5>
            </v-col>
            <v-col cols="6" class="text-left">
              <h5>{{ selectedCategoryInformation[item.value] }}</h5>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { apiErrorMessage, debounce } from "@/utils/general";
import Pagination from "../components/Pagination.vue";
import CategoryForm from "../components/CategoryForm.vue";
import CategoryDelete from "../components/CategoryDeleteModal.vue";
export default {
  components: {
    Pagination,
    CategoryForm,
    CategoryDelete,
  },
  data() {
    return {
      isCategoryDataLoading: false,
      selectedCurrencies: [],
      categoryList: [],
      selectedCategoryData: {},
      page: 1,
      currentPage: 1,
      pageLimit: 10,
      searchText: "",
      isEditMode: false,
      showFormDialog: false,
      showSnackbar: false,
      snackbarOption: {
        icon: "",
        message: "",
        color: "",
      },
      showDeleteModal: false,
      selectedDataToDelete: {},
      showInfoModal: false,
      selectedCategoryInformation: {},
    };
  },
  watch: {
    searchText: debounce(function () {
      this.fetchCategoryData();
    }, 300)
  },
  methods: {
    ...mapActions("accounting", ["getCategories"]),

    onSelectCategoryInformation(data) {
      this.selectedCategoryInformation = data;
      this.onToggleCategoryInformationModal();
    },

    onToggleCategoryInformationModal() {
      this.showInfoModal = !this.showInfoModal;
      if (!this.showInfoModal) {
        this.selectedCategoryInformation = {};
      }
    },
    onToggleCategoryForm(options = {}) {
      this.isEditMode = false;
      // this.selectedServiceData = {};
      this.showFormDialog = !this.showFormDialog;
      if (options.created || options.updated) {
        this.snackbarOption = {
          icon: "mdi-check",
          color: "success",
          message: options.message,
        };
        this.showSnackbar = true;
        this.showInfoModal = false;
        this.fetchCategoryData();
      }
    },

    onSelectCategoryToDelete(data = {}) {
      this.selectedDataToDelete = JSON.parse(JSON.stringify(data));
      this.onToggleDeleteCategoryForm();
    },

    onToggleDeleteCategoryForm(options = {}) {
      this.showDeleteModal = !this.showDeleteModal;

      if (options.deleted) {
        this.snackbarOption = {
          icon: "mdi-delete",
          color: "red",
          message: options.message || "Deleted.",
        };
        this.showSnackbar = true;
        this.showInfoModal = false;

        this.fetchCategoryData();
      }
    },

    onSelectEditCategoryData(data = {}) {
      this.selectedCategoryData = JSON.parse(JSON.stringify(data));
      this.isEditMode = true;
      this.showFormDialog = true;
    },

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchCategoryData();
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchCategoryData();
    },
    async fetchCategoryData() {
      if (this.isCategoryDataLoading) {
        return;
      }
      this.isCategoryDataLoading = true;
      try {
        const data = await this.getCategories({
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || "",
        });
        this.categoryList = data;
        this.isCategoryDataLoading = false;
      } catch (error) {
        this.isCategoryDataLoading = false;
        apiErrorMessage(error);
      }
    },

		rowActive(item){
			const { id } = this.selectedCategoryInformation
			if( item.id === id ){
				return 'cyan lighten-4';
			}
			return null;
		},

  },
  created() {
    this.fetchCategoryData();
  },
  computed: {
    tableData: {
      get() {
        return this.categoryList?.data || [];
      },
    },

    pagination: {
      get() {
        return this.categoryList.data
          ? this.categoryList.meta.pagination
          : {};
      },
    },

    tableHeader() {
      return [
        {
          text: this.$t("name"),
          value: "name",
          class: "text-uppercase th--text font-weight-bold",
        },
        {
          text: this.$t("type"),
          value: "type",
          class: "text-uppercase th--text font-weight-bold",
          align: "center",
        },
        {
          text: this.$t("color"),
          value: "color",
          class: "text-uppercase th--text font-weight-bold",
          align: "center",
        },
        {
          text: this.$t("enabled"),
          value: "enabled",
          class: "text-uppercase th--text font-weight-bold",
          align: "center",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

  },
};
</script>
<style lang="scss" scoped>
.vue-app {
  background: none;
}

.v-tabs {
  border-bottom: 1px solid #ddd;

  .v-tab {
    color: #0889a0;
  }
}
/* .separator {
  font-size: 1.5rem;
  font-weight: bold;
} */
.container {
  background-color: none;
}

.w-100 {
  width: 100%;
}

.v-list-item {
  min-height: 36px;
}
.row-stripe:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}
.dialog {
  z-index: 9999;
}
</style>
<style lang="scss">
.v-table-middle-align {
  td,
  th {
    vertical-align: middle !important;
  }
}
.select-page-limit {
  width: 80px;
}
</style>