<template>
  <v-form ref="categoryForm">
    <v-dialog
      v-model="showDialog"
      max-width="600"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card :loading="isSaving">
        <v-card-title class="pa-0 z-index-front">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{
              isEditMode ? $t("edit_category") : $t("add_category")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-3">

          <label class="form-label text-uppercase" for="formdata-name">{{ $t("name") }}</label>
          <v-text-field
            v-model="formData.name"
            :label="$t('input_some_value')"
            solo
            class="app-theme-input-border"
            flat
            required
            dense
            outlined
            id="formdata-name"
            :rules="[(v) => !!v || $t('field_required'),]"
          ></v-text-field>

          <label class="form-label text-uppercase" for="formdata-type">{{ $t("type") }}</label>
          <v-select
            v-model="formData.type"
            :items="categoryTypeList"
            solo
            flat
            class="app-theme-input-border"
            dense
            outlined
            :rules="[(v) => !!v || $t('field_required'),]"
          >
          </v-select>

          <label class="form-label text-uppercase">{{ $t("color") }}</label>
          <v-color-picker v-model="formData.color" mode="hexa" width="580"></v-color-picker>

          <div>
            <label class="form-label text-uppercase">{{ $t("enabled") }}</label>
            <div>
              <v-btn small rounded outlined :color="formData.enabled ? 'success': 'error'" class="pa-4" @click="onToggleEnable">
                <div v-if="formData.enabled" class="d-flex align-center justify-space-around" style="min-width: 60px">
                  <v-icon>mdi-check-circle</v-icon>
                  {{ $t('yes') }}
                </div>
                <div v-else class="d-flex align-center justify-space-around" style="min-width: 60px">
                  <v-icon>mdi-minus-circle</v-icon>
                  {{ $t('no') }}
                </div>
              </v-btn>
            </div>
          </div>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-start">
          <v-btn
            @click="onSaveForm"
            class="text-capitalize"
            color="primary"
            v-if="!isEditMode"
            :loading="isSaving"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            @click="onSaveForm"
            class="text-capitalize"
            color="primary"
            v-if="isEditMode"
            :loading="isSaving"
            >{{ $t("update") }}</v-btn
          >
          <v-btn
            text
            outlined
            class="text-capitalize primary--text"
            @click="onClose"
            :disabled="isSaving"
            >{{ $t("cancel") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-snackbar
					timeout="5000"
					vertical
					:color="snackbarOption.color"
					v-model="showSnackbar"
					bottom
				>
				<v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
      </v-snackbar>
    </v-dialog>
  </v-form>
</template>

<script>
import AkauntingService from '@/services/akaunting/akaunting.service';

export default {
  props: ["open", "isEditMode", "formValues"],
  data: () => ({
    formData: {
      name: '',
      type: '',
      color: '#FFFFFF',
      enabled: 1,
    },
    defaultFields: null,
    isSaving: false,
    snackbarOption: {},
    showSnackbar: false,
  }),
  created() {
    this.defaultFields = JSON.parse(JSON.stringify(this.formData));
  },
  watch: {
    formValues(value) {
      if(this.open && this.isEditMode) {
        this.formData = {
          id: value.id,
          name: value.name,
          type: value.type,
          color: (value.color || '').toUpperCase(),
          enabled: value.enabled ? 1 : 0,
        };
      }
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.open;
      },
    },
    categoryTypeList() {
      return [
        { text: 'Expense', value: 'expense' },
        { text: 'Income', value: 'income' },
        { text: 'Item', value: 'item' },
        { text: 'Other', value: 'other' },
      ]
    }
  },
  methods: {
    onClose() {
      this.$refs.categoryForm.reset();
      this.formData = {
        ...this.defaultFields
      };
      this.showSnackbar = false;
      this.$emit("toggle");
    },

    onToggleEnable() {
      this.formData = {
        ...this.formData,
        enabled: this.formData.enabled === 1 ? 0 : 1,
      };
    },

    async onSaveForm() {
      if (this.isSaving) {
        return;
      }

      const validated = this.$refs.categoryForm.validate();
      if (validated) {
        try {

          this.isSaving = true;
          const isEdit = this.isEditMode;
          const formData = {...this.formData};
          const data = isEdit
            ? await AkauntingService.updateCategory(formData)
            : await AkauntingService.createCategory(formData);

          const message = data.message || "Data was successfully saved.";

          this.snackbarOption = {
              icon: "mdi-check",
              color: "success",
              message,
            };
          this.$emit("toggle", { created: true, message });

        } catch(error) {
          console.log(error);
          const { data } = error.response || { data: {} };

          this.snackbarOption = {
            icon: "mdi-alert-circle",
            color: "error",
            message: data.message || "Could not save the data.",
          };
        } finally {
          this.showSnackbar = true;
          this.isSaving = false;
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
$button-bg-color: #0171a1;
$form-label: #819fb2;
.dialog {
  z-index: 9999;
}

.form-label {
  color: $form-label;
}

.border-dashed {
  border: 1px dashed $form-label !important;
}

.z-index-front {
  z-index: 1;
}
</style>
<style lang="scss">
.app-theme-input-border {
  .v-input__slot {
    border: 1px solid #b5d0e1 !important;
  }
  label {
    color: #a3c6dd !important;
  }
}
</style>