<template>
  <div class="widgetContainer__body settings">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">
          {{ $t('side_menu_settings') }}
        </p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid class="">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" lg="4">
                  <v-card flat>
                    <v-card-text class="d-flex align-center">
                      <v-btn text class="primary" rounded style="border-radius: 10px" width="48" height="48" fab  @click="componentWasClicked('qbosettings')">
                        <v-icon size="30" color="white" class="icon">mdi-domain</v-icon>
                      </v-btn>
                      <div class="flex-grow ml-4">
                        <h5 class="font-weight-bold primary--text">{{ $t('quickbooks') }}</h5>
                        <v-btn v-if="QBOInformation" outlined class="d-flex mt-1" small color="red white--text" @click="onQBOSignOut" :loading="isQBOSigningOut">
                          <v-icon class="mr-2">mdi-power</v-icon>
                          <h5 class="text-capitalize">{{ $t('qbo_disconnect') }}</h5>
                        </v-btn>
                        <v-btn v-else outlined class="d-flex" color="primary" small @click="onSignIn" :loading="isFetchingSignInURL || isCheckingQBO">
                          <v-icon class="mr-2">mdi-login-variant</v-icon>
                          <h5>{{ $t('sign_in_qbo') }}</h5>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="4" v-for="link in linkSettings" :key="link.title">
                  <v-card class="setting-list-component" @click="componentWasClicked(link.component)" flat :disabled="!link.component">
                    <v-card-text class="d-flex align-center">
                      <v-icon size="30" color="white" class="icon primary pa-2" style="border-radius: 10px">{{ link.icon }}</v-icon>
                      <div class="flex-grow ml-4">
                        <h5 class="font-weight-bold primary--text">{{ link.title }}</h5>
                        <h6>{{ link.description }}</h6>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-main>
      <v-dialog v-model="showDialog" max-width="1366" scrollable class="dialog" persistent>
        <v-card flat>
          <v-card-title class="pa-0 z-index-front">
            <v-toolbar light elevation="1" class="">
              <v-toolbar-title>{{ $t('side_menu_settings') }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <currencies v-if="componentActive('currencies')"></currencies>
            <qbo-settings v-if="componentActive('qbosettings')"></qbo-settings>
            <category-settings v-if="componentActive('categorysettings')"></category-settings>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { apiErrorMessage } from '@/utils/general';
import { Notification } from 'element-ui';
import Currencies from './Currencies.vue'
import QBOSettings from '../components/QBOSettings.vue';
import CategorySettings from './Category.vue';
export default {
  components:{
    Currencies,
    qboSettings: QBOSettings,
    CategorySettings,
  },
  data() {
    return {
      isFetchingSignInURL: false,
      isQBOSigningOut: false,
      selectedComponent: '',
      showDialog: false,
      linkSettings: [
        { title: this.$t('company_title'), description: this.$t('company_description'), icon: 'mdi-office-building', component: '' },
        { title: this.$t('localisation_title'), description: this.$t('localisation_description'), icon: 'mdi-map-marker', component: '' },
        { title: this.$t('invoice_title'), description: this.$t('invoice_description'), icon: 'mdi-file-document', component: '' },
        { title: this.$t('default_title'), description: this.$t('default_description'), icon: 'mdi-tune', component: '', },
        { title: this.$t('email_title'), description: this.$t('email_description'), icon: 'mdi-email', component: '', },
        { title: this.$t('scheduling_title'), description: this.$t('scheduling_description'), icon: 'mdi-update', component: '', },
        { title: this.$t('categories_title'), description: this.$t('categories_description'), icon: 'mdi-folder', component: 'categorysettings', active: true, },
        { title: this.$t('currencies_title'), description: this.$t('currencies_description'), icon: 'mdi-currency-usd', component: 'currencies', active: true, },
        { title: this.$t('taxes_title'), description: this.$t('taxes_description'), icon: 'mdi-percent', component: '', },
        // { title: this.$t('paypal_standard_title'), description: this.$t('paypal_standard_description'), icon: 'mdi-alpha-p', component: '' },
        { title: this.$t('double_entry_title'), description: this.$t('double_entry_description'), icon: 'mdi-scale-balance', component: '', },
        // { title: this.$t('offline_payments_title'), description: this.$t('offline_payments_description'), icon: 'mdi-credit-card-outline', component: '' },
      ]
    }
  },

  computed: {
    ...mapState('accounting', ['isCheckingQBO', 'QBOInformation']),
    ...mapGetters('accounting', ['isQBOEnabled']),
  },

  created() {
    /* if(this.QBOInformation === null) {
      this.fetchQBO();
    } */
  },

  methods: {
    ...mapActions('accounting', 
    [
      'getQBOConnection',
      'getQuickbooksLoginUrl',
      'setLastUrlPath',
      'signOutQBO',
    ]),

    ...mapMutations('accounting', ['updateQBO']),

    closeDialog(){
      this.showDialog = false
      this.selectedComponent = ''
    },
    componentWasClicked(component){
      if(component){
        this.selectedComponent = component
        this.showDialog = true
      }
    },
    componentActive(str){
      return this.selectedComponent === str ? true : false
    },
    async fetchQBO() {
      try {
        const { data, message } = await this.getQBOConnection();
        if(!data) {
          apiErrorMessage(message);
        } else {
          this.updateQBO(data);
        }
      } catch(error) {
        apiErrorMessage(this.$t('qbo_fetch_error'));
      }
    },

    async onSignIn() {
      if(this.isFetchingSignInURL) {
        return;
      }

      this.isFetchingSignInURL = true;
      try {
        this.setLastUrlPath(this.$route.path);
        const url = await this.getQuickbooksLoginUrl();
        
        // Already signed-in
        if(url) {
          window.open(url, "_self");
        }

        // Already connected
        if(url === null) {
          this.fetchQBO();
        }
      } catch(error) {
        apiErrorMessage(error);
        this.isFetchingSignInURL = false;
      }
    },

    async onQBOSignOut() {
      if(this.isQBOSigningOut) {
        return;
      }

      this.isQBOSigningOut = true;

      try {
        const { message } = await this.signOutQBO();
        this.updateQBO(null);
        this.messageBox(message);
        this.isQBOSigningOut = false;
      } catch(error) {
        this.isQBOSigningOut = false;
        apiErrorMessage(error);
      }
    },

    messageBox(message) {
      Notification.closeAll();
      Notification({
        message,
        type: 'info',
      });
    }

  }

}
</script>

<style lang="scss" scoped>
  .settings {

    .v-application--wrap {
      min-height: auto !important;
    }

    .vue-app {
      background: none !important;
    }

    .v-card {
      background: #fff;
    }

  }
  .setting-list-component:hover{
    transition: 0.4s ease-in-out;
    background-color: var(--shifl-light-grey);
  }
  .dialog{
    z-index: 9999;
  }
  .z-index-front{
    z-index: 1;
  }
</style>